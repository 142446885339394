export const FILES_STAGES = {
  RAW: "raw",
  PROCESSED: "processed",
  FILTERED: "filtered",
};

export const ENTITY_COLUMNS = [
  {
    labelKey: "tblNameLbl",
    name: "name",
    helperKey: "description",
    className: "w-5/12",
  },
  {
    labelKey: "groupSubCategoryLbl",
    name: "subCategory",
    className: "w-2/12",
    type: "subCategory",
  },
  {
    labelKey: "tblFileDateLbl",
    name: "date",
    className: "w-2/12",
  },
  {
    labelKey: "tblFileSizeLbl",
    name: "size",
    className: "w-1/12",
  },
  {
    labelKey: "tblFileIsPublicLbl",
    name: "isPublic",
    className: "w-1/12",
    type: "boolean",
  },
];

export const GEO_FOLDER_COLUMNS = [
  {
    labelKey: "idLbl",
    name: "id",
    className: "w-4/24",
    isHidden: true,
  },
  {
    labelKey: "nameLbl",
    name: "name",
    helperKey: "description",
    className: "w-6/24",
  },
  {
    labelKey: "typeLbl",
    name: "type",
    className: "w-2/24",
  },
  {
    labelKey: "dateLbl",
    name: "date",
    className: "w-2/24",
  },
  {
    labelKey: "sizeLbl",
    name: "size",
    className: "w-1/24",
  },
  {
    labelKey: "countLbl",
    name: "count",
    className: "w-1/24",
  },
];
export const SINGLE_FILES_COLUMNS_GEO = [
  ...GEO_FOLDER_COLUMNS,

  {
    labelKey: "",
    name: "link",
    type: "download",
    className: "w-1/12",
  },
];

export const FILE_ACTIONS = {
  MAKE_PUBLIC: "makePublic",
  MARK_INVALID: "markInvalid",
  VIEW: "view",
};

export const MAKE_ACTIONS_LABELS = {
  [FILE_ACTIONS.VIEW]: {
    key: FILE_ACTIONS.VIEW,
    // key from translation
    labelKey: "tblFileViewBtn",
  },
  [FILE_ACTIONS.MAKE_PUBLIC]: {
    key: FILE_ACTIONS.MAKE_PUBLIC,
    // key from translation
    labelKey: "tblFileMakePublicBtn",
  },
  // [FILE_ACTIONS.MARK_INVALID]: {
  //   key: FILE_ACTIONS.MARK_INVALID,
  //   // key from translation
  //   labelKey: "tblFileMarkInvalidBtn",
  //   className: "text-red",
  // },
};
