import React from "react";
import { Trans, useTranslation } from "react-i18next";
import LayoutWithSidebar from "layout/layout-with-sidebar";
import LinkText from "components/link/link-text";
import TagH1 from "components/typography/h1";
import Categories from "../data/categories";

const LINKS = {
  scar_org: (
    <LinkText
      href="https://scar.org/"
      target="_blank"
      className="underline hover:no-underline"
    />
  ),
  mon_gov_ua: (
    <LinkText
      href="https://mon.gov.ua/"
      target="_blank"
      className="underline hover:no-underline"
    />
  ),
  uac_gov_ua: (
    <LinkText
      href="http://uac.gov.ua/"
      target="_blank"
      className="underline hover:no-underline"
    />
  ),
  zakon_rada_gov_ua_995_224: (
    <LinkText
      href="https://zakon.rada.gov.ua/laws/show/995_224"
      target="_blank"
      className="underline hover:no-underline"
    />
  ),
  nas_gov_ua: (
    <LinkText
      href="https://www.nas.gov.ua/"
      target="_blank"
      className="underline hover:no-underline"
    />
  ),
  zakon_rada_gov_ua_1002_2010: (
    <LinkText
      href="https://zakon.rada.gov.ua/laws/show/1002-2010-%D0%BF#Text"
      target="_blank"
      className="underline hover:no-underline"
    />
  ),
};

const ResearchStructure = () => {
  const { t } = useTranslation();

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={t("structureTitle")} />

        <div className="flex flex-col gap-8 dark:text-gray-dark-500">
          <p>
            <Trans i18nKey="structureParagraph1" components={LINKS} />
          </p>
          <p>
            <Trans i18nKey="structureParagraph2" components={LINKS} />
          </p>
          <p>
            <Trans i18nKey="structureParagraph3" components={LINKS} />
          </p>

          <Categories />
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default ResearchStructure;
