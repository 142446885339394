import React from "react";
import PropTypes from "prop-types";
import { TbHome } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import LinkText from "../link/link-text";

const BreadCrumbs = ({ links = [], currentPage }) => {
  const { t } = useTranslation();

  const showLinks = links.length > 0;

  return (
    <div className="flex items-center text-xs flex-wrap mb-8">
      <LinkText className="flex items-center gap-x-1" href="/">
        <TbHome />
        <span className="capitalize text-color-brands">{t("home")}</span>
      </LinkText>

      {showLinks &&
        links.map(({ link, name, icon }, index) => (
          <span key={index} className="flex items-center gap-1">
            <IoIosArrowForward />{" "}
            <LinkText className="flex items-center gap-1" href={link}>
              <span className="text-color-brands">{name}</span>
            </LinkText>
          </span>
        ))}

      {currentPage && (
        <>
          <IoIosArrowForward />{" "}
          <span className="text-gray-500 dark:text-gray-dark-500">
            {currentPage}
          </span>
        </>
      )}
    </div>
  );
};
BreadCrumbs.propTypes = {
  currentPage: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

export default BreadCrumbs;
