import { useTranslation } from "react-i18next";
import { TiWarning } from "react-icons/ti";

export function LoadingContainer({ isLoading, error, children }) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl p-6">
        {t("loadingText")}
      </div>
    );
  }

  if (!!error) {
    return (
      <div className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border rounded-2xl p-6">
        <div className="flex items-center gap-2">
          {" "}
          <TiWarning />
          {t("loadingErrorText")}
        </div>
        {error?.message && (
          <div className="text-sm italic mt-8 opacity-50">{error?.message}</div>
        )}
      </div>
    );
  }

  return children;
}
