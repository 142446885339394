import React from "react";
import { LANGUAGE } from "i18n";

export const getIdFromTitle = (text = "") =>
  text.toLowerCase().replace(/[^a-zа-я]+/g, "");

const MenuItem = ({ language, title, titleEn }) => {
  const isEn = language === LANGUAGE.EN;

  if (!title && !titleEn) {
    return null;
  }
  const titleText = (isEn && titleEn) || title;
  const titleTextId = getIdFromTitle(titleEn || title);

  const handleClick = () => {
    document.querySelector(`#${titleTextId}`).scrollIntoView();
  };

  return (
    <div className="mb-2">
      <button onClick={handleClick} className="text-left">
        {titleText}
      </button>
    </div>
  );
};

export default MenuItem;
