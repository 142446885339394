export const FOLDERS_MOCK = [
  {
    id: "aksahiyq298i2",
    name: "Вимірювання озону спектрофотометром",
    description: "Дані за день",
    category: "HYDROMETEOROLOGY",
    subCategory: "ozone",
    // dd/MM/yyyy hh:mm:ss
    date: "14/05/2023",
    size: "1.2 MB",
    isPublic: true,
  },
];

// NOTE: this is just a random data
export const getRandomData = ({ currentPage, filesPerPage }) => {
  const offset = currentPage * filesPerPage;
  function getDate(idx) {
    const day = offset + idx + Math.ceil(Math.random() * 10);
    return `${((day % 30) + 1).toString().padStart(2, "0")}/${(Math.ceil(day / 31) + 3).toString().padStart(2, "0")}/2022`;
  }
  const data = [...Array(filesPerPage)].map((item, idx) => ({
    ...FOLDERS_MOCK[0],
    date: getDate(idx),
    size: `${(Math.random() + 1).toFixed(2)} MB`,
  }));

  const dataTotal = 40;
  const dataToSlice = dataTotal < 10 ? dataTotal : 10;

  return {
    data: data.slice(0, dataToSlice),
    totalCount: dataTotal,
  };
};

export const FILES_TAGS_MOCK = [
  {
    key: "ozone",
    name: "Ozone",
    category: "type",
  },
  {
    key: "temperature-0",
    name: "0",
    category: "temperature",
  },
  {
    key: "temperature-10",
    name: "10",
    category: "temperature",
  },
  {
    key: "temperature--10",
    name: "-10",
    category: "temperature",
  },
  {
    key: "isPublic",
    name: "Yes",
    category: "isPublic",
  },
];

export const FOLDER_RESPONSE_MOCK = {
  id: 1,
  title: "Вимірювання озону спектрофотометром",
  tags: {
    temperature: ["-10"],
    isPublic: [true, false],
  },
  startDate: "2021-10-10",
  endDate: "2021-10-10",
  files: [
    {
      id: "726w8ibi8as69",
      name: "file.pdf",
      link: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      isPdf: true,
    },
    {
      id: "726w8ibi8as69",
      name: "file.pdf",
      isChart: true,
    },
  ],
};

export const DATA_MOCK_DATA_PRESENCE = [
  { date: "01/09/23", value: 30 },
  { date: "02/09/23", value: 50 },
  { date: "09/09/23", value: 10 },
  { date: "04/09/23", value: 40 },
  { date: "05/09/23", value: 70 },
  { date: "06/09/23", value: 5 },
  { date: "07/09/23", value: 0 },
  { date: "08/09/23", value: 11 },
  { date: "09/09/23", value: 75 },
  { date: "10/09/23", value: 60 },
  { date: "11/09/23", value: 50 },
  { date: "12/09/23", value: 40 },
];

const common_data = {
  link: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  meta: {
    // dd/MM/yyyy hh:mm:ss
    start: "08/09/2022 06:00:00",
    end: "08/09/2022 18:00:00",
    size: "3.2 GB",
    device: 123,
    code: "CDZC",
    cloudiness: 0.8,
  },
  chartData: [
    {
      time: "06:00",
      value: 230,
    },
    {
      time: "09:00",
      value: 235,
    },
    {
      time: "12:00",
      value: 225,
    },
    {
      time: "15:00",
      value: 240,
    },
    {
      time: "18:00",
      value: 230,
    },
  ],
};

export const FILES_MOCK = [
  {
    id: "726w8ibi8as69",
    name: "vozSep2022.dat",
    type: "voz",
    date: "08/09/2022",
    ...common_data,
  },
  {
    id: "tibi8726wasi9",
    name: "vozSep2022.txt",
    date: "08/09/2022",
    type: "vtx",
    ...common_data,
  },
  {
    id: "tibifewalasyt",
    name: "vozMay2022.dat",
    date: "02/05/2022",
    type: "voz",
    ...common_data,
  },
];
