import {
  DATA_INTERVALS,
  DATA_TYPES,
  MAGNET_TAGS,
  SCALAR_INSTRUMENTS,
  VECTOR_INSTRUMENTS,
} from "./constants";

export const GEOLOGY_AND_GEOPHYSICS_SINGLE_RESPONSE_MOCK = {
  start: "2023-03-01",
  end: "2023-03-07",
  title: "AIA (Vernadsky, Argentine Islands)",
  subtitle: "Data type: definitive, 1 minute data",
  footer:
    "Days from 01-Mar-2023 (day number 060) to 07-Mar-2023 (day number 066)",
  tags: [
    {
      category: MAGNET_TAGS.VECTOR_INSTRUMENT,
      key: VECTOR_INSTRUMENTS.PRIMARY,
    },
    {
      category: MAGNET_TAGS.DATA_TYPE,
      key: DATA_TYPES.PRELIMINARY,
    },
    {
      category: MAGNET_TAGS.DATA_INTERVAL,
      key: DATA_INTERVALS.MINUTE,
    },
    {
      category: MAGNET_TAGS.SCALAR_INSTRUMENT,
      key: SCALAR_INSTRUMENTS.PRIMARY,
    },
  ],
};
