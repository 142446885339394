import React from "react";
import PropTypes from "prop-types";
import Language from "../../locales/language";
import ThemeSwitch from "./theme-switch";

const Settings = ({ isCollapsed = false }) => {
  return (
    <div
      className={`flex justify-items-center items-center gap-2
        ${isCollapsed ? "md:flex-col" : ""}`}
    >
      <ThemeSwitch />

      <Language isCollapsed={isCollapsed} />
    </div>
  );
};
Settings.propTypes = {
  isCollapsed: PropTypes.bool,
};

export default Settings;
