export const DATA_KEYS = {
  DATA: "data",
  CATEGORIES: ["data", "categories"],
  getCategoryKeys: ({ category, limit, offset }) => [
    "data",
    "categories",
    category,
    limit,
    offset,
  ],
  getFolderKeys: ({ category, folderId, limit, offset }) => [
    "data",
    "categories",
    category,
    folderId,
    limit,
    offset,
  ],
  getFileKeys: ({ category, folderId, fileId }) => [
    "data",
    "categories",
    category,
    folderId,
    fileId,
  ],
};

export const META_KEYS = {
  META: "meta",
  CATEGORIES: ["meta", "categories"],
  getCategoryKeys: ({ category }) => ["meta", "categories", category],
  getFolderKeys: ({ category, folder }) => [
    "meta",
    "categories",
    category,
    folder,
  ],
};
