import React from "react";
import PropTypes from "prop-types";
import { TABLE_ROW_ACTION_SHAPE } from "./shapes";
import ExpandButton from "./expand-button";
import CellContent from "./cell-content";
import CellAction, { ACTION_PLACEMENTS } from "./cell-action";

const Row = ({
  expandedRowIdx,
  setExpandedRowIdx,
  trIdx,
  row,
  rowActions,
  dataLen,
  columns,
  ExpandedRowComponent = null,
  onClick = () => {},
}) => {
  const getActionPlacement = (trIdx) => {
    return trIdx + 1 === dataLen
      ? ACTION_PLACEMENTS.TOP
      : ACTION_PLACEMENTS.BOTTOM;
  };

  const hasExpandedRow = !!ExpandedRowComponent;
  const hasActions = rowActions.length > 0;

  return (
    <React.Fragment key={trIdx}>
      <tr
        key={`row-${trIdx}`}
        className={`border-t border-neutral-200 dark:border-dark-neutral-border ${hasExpandedRow ? "cursor-pointer select-none" : ""}`}
        onClick={() => {
          setExpandedRowIdx(expandedRowIdx === trIdx ? null : trIdx);
          onClick(row);
        }}
      >
        {columns
          .filter(({ isHidden }) => !isHidden)
          .map(({ name, helperKey, className, type }, tdIdx) => (
            <td
              key={`${name}-${tdIdx}`}
              className={`py-4 dark:text-dark-neutral-400 text-left pr-4 ${className}`}
            >
              <div className={"flex items-center"}>
                {hasExpandedRow && tdIdx === 0 && (
                  <ExpandButton expandedRowIdx={expandedRowIdx} trIdx={trIdx} />
                )}
                <div className="flex flex-col">
                  <CellContent
                    row={row}
                    name={name}
                    helperKey={helperKey}
                    type={type}
                  />
                </div>
              </div>
            </td>
          ))}
        {hasActions && (
          <td
            key={`actions-${trIdx}`}
            className="py-4 w-1/12 dark:text-dark-neutral-400 text-right"
          >
            <CellAction
              actions={rowActions}
              row={row}
              placement={getActionPlacement(trIdx)}
            />
          </td>
        )}
      </tr>
      {hasExpandedRow && expandedRowIdx === trIdx && (
        <tr>
          <td colSpan="100%">
            <ExpandedRowComponent row={row} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any),
  dataLen: PropTypes.number,
  trIdx: PropTypes.number,
  row: PropTypes.shape({}),
  rowActions: TABLE_ROW_ACTION_SHAPE,
  expandedRowIdx: PropTypes.number,
  setExpandedRowIdx: PropTypes.func,
  ExpandedRowComponent: PropTypes.func,
};

export default Row;
