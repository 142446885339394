import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { LineChart, XAxis, YAxis, ResponsiveContainer } from "recharts";

import MAGNETIC_MOCK from "./magnetic_data.json";
import GeologyChart, {
  LABEL_POSITION,
  LINE_CHART_MARGIN,
} from "./geology-chart";

const formatChartData = ({ time, X, Y, Z, G }) => ({
  xData: X.map((value, index) => ({ date: time[index], value })),
  yData: Y.map((value, index) => ({ date: index, value })),
  zData: Z.map((value, index) => ({ date: index, value: value.toFixed(3) })),
  gData: G.map((value, index) => ({ date: index, value })),
  timeData: time.map((value, index) => ({ date: value?.slice(0, 16) ?? "" })),
});
const stationData = {
  Format: "IAGA-2002",
  "Source of Data": "NASC",
  "Station Name": "Vernadsky, Argentine Islands",
  "IAGA Code": "AIA",
  "Geodetic Latitude": "-65.250",
  "Geodetic Longitude": "295.730",
  Elevation: "10",
  Reported: "XYZG",
  "Sensor Orientation": "XYZF",
  "Digital Sampling": "1.000000",
  "Data Interval Type": "1-minute",
  "Data Type": "Definitive",
  "Publication Date": "2024-01-01",
};
const SingleGeologyGeophysicsExpanded = ({
  row: { chartData, meta } = {},
} = {}) => {
  const { xData, yData, zData, gData, timeData } = useMemo(() => formatChartData(MAGNETIC_MOCK),
    [],
  );

  const getTooltipContent = useCallback(({ label }) => {
    if (typeof label === "undefined") return null;

    return (
      <div className="bg-neutral dark:bg-dark-neutral-bg border-neutral color-neutral p-2 rounded-lg text-sm">
        {/*// fixme: set correct date format*/}
        <div className="text-xs mb-2">{xData[label].date}</div>
        <div>X: {xData[label]?.value ?? "-"} (nt)</div>
        <div>Y: {yData[label]?.value ?? "-"} (nt)</div>
        <div>Z: {zData[label]?.value ?? "-"}</div>
        <div>G: {gData[label]?.value ?? "-"}</div>
      </div>
    );
  }, [xData, yData, zData, gData]);

  if (!chartData && !meta) return null;

  return (
    <div className="w-full flex flex-col shadow-inner py-8">
      <div className="w-full p-4 bg-gray-50 dark:bg-gray-800 rounded-md shadow-md mb-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
          {Object.entries(stationData).map(([key, value]) => (
            <div
              key={key}
              className="flex justify-between border-b-[0.5px] pb-1"
            >
              <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                {key}
              </span>
              <span className="text-sm text-gray-800 dark:text-gray-100">
                {value}
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* X */}
      <GeologyChart
        chartData={xData}
        yLabel={{
          value: "X (nt)",
          ...LABEL_POSITION,
        }}
        color="#FF0000"
        getTooltipContent={getTooltipContent}
        showLine={false}
      />
      {/* Y */}
      <GeologyChart
        chartData={yData}
        yLabel={{
          value: "Y (nt)",
          ...LABEL_POSITION,
        }}
        color="#82ca9d"
        getTooltipContent={getTooltipContent}
      />
      {/* Z */}
      <GeologyChart
        chartData={zData}
        yLabel={{
          value: "Z (nt)",
          ...LABEL_POSITION,
        }}
        color="#1E90FF"
        domain={["dataMin", "dataMax"]}
        getTooltipContent={getTooltipContent}
      />
      {/* Missing data */}
      <GeologyChart
        chartData={gData}
        yLabel={{
          value: "G (difference - nt)",
          ...LABEL_POSITION,
          dy: -60,
        }}
        label="G (difference - nt)"
        color="#767681"
        getTooltipContent={getTooltipContent}
        domain={["dataMin - 0.1", "dataMax + 0.11"]}
      />
      <ResponsiveContainer width="100%" height={30}>
        <LineChart data={timeData} margin={LINE_CHART_MARGIN}>
          <YAxis />
          <XAxis dataKey="date" className="text-xs" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

SingleGeologyGeophysicsExpanded.propTypes = {
  row: PropTypes.shape({
    chartData: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }),
};

export default SingleGeologyGeophysicsExpanded;
