import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Settings from "../settings/settings";
import LinkText from "../link/link-text";

const Footer = ({ showSettings }) => {
  const { t } = useTranslation();

  return (
    <footer
      className="py-3
      border-t border-t-neutral dark:border-t-dark-neutral-border
      font-light text-gray-900 dark:text-gray-300 relative
      bg-white dark:bg-dark-neutral-bg"
    >
      <div
        className="flex flex-grow-0 items-center justify-between
       text-desc font-normal flex-wrap gap-5 px-[25px]"
      >
        <div className="flex items-center gap-2 flex-wrap">
          <p>
            <span>© {new Date().getFullYear()} -</span>{" "}
            <LinkText href="http://uac.gov.ua/en/" target="_blank">
              {t("vernadskyResearchBase")}
            </LinkText>
          </p>
        </div>
        {showSettings && <Settings />}
      </div>
    </footer>
  );
};
Footer.propTypes = {
  showSettings: PropTypes.bool,
};

export default Footer;
