import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CategoryContent = ({ img, darkImg, labelKey }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-10 h-10 md:w-28 md:h-28 flex items-center justify-center rounded-full">
        <img src={img} alt="" className="block dark:hidden" />
        <img src={darkImg} alt="" className="hidden dark:block" />
      </div>
      <div className="text-color-brands">{t(labelKey)}</div>
    </>
  );
};

const Category = ({ disabled, img, darkImg, link, labelKey }) => {
  if (disabled) {
    return (
      <div
        className="flex flex-col gap-4 items-center justify-center  p-6
          border dark:border-dark-neutral-border
          rounded-lg shadow-lg bg-white dark:bg-dark-neutral-bg opacity-50"
      >
        <CategoryContent img={img} labelKey={labelKey} darkImg={darkImg} />
      </div>
    );
  }

  return (
    <Link
      to={link}
      className="flex flex-col gap-4 items-center justify-center p-6
         rounded-lg shadow-lg bg-white dark:bg-black hover:shadow-md
         dark:bg-dark-neutral-bg"
    >
      <CategoryContent img={img} labelKey={labelKey} darkImg={darkImg} />
    </Link>
  );
};

export default Category;
