import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "components/breadcrumbs";
import LayoutWithSidebar from "layout/layout-with-sidebar";
import TagH1 from "components/typography/h1";

import Categories from "./categories";

const DataCategories = () => {
  const { t } = useTranslation();
  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={t("dataTitle")} />

        <BreadCrumbs currentPage={t("dataTitle")} />

        <section>
          <Categories />
        </section>
      </div>
    </LayoutWithSidebar>
  );
};

export default DataCategories;
