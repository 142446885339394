import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BreadCrumbs from "components/breadcrumbs";
import LayoutWithSidebar from "layout/layout-with-sidebar";
import Scale from "components/charts/scale";
import Table from "components/table/table";
import TagH1 from "components/typography/h1";
import { Tags, formatTags } from "components/tags";

import { CATEGORIES } from "api/data/constants";
import {
  DATA_MOCK_DATA_PRESENCE,
  FILES_MOCK,
  FOLDER_RESPONSE_MOCK,
} from "./hydro-files-mock.js";

import SingleHydroMeteorologyExpanded from "./single-hydrometeorology-expanded";
import { GEO_FOLDER_COLUMNS } from "../data-columns";
import { CATEGORIES_CONFIG } from "../categories-settings";

const SingleHydroMeteorologyPage = ({ id }) => {
  const { t } = useTranslation();
  const categoryTitle = t(
    CATEGORIES_CONFIG[CATEGORIES.HYDROMETEOROLOGY].labelKey,
  );

  const [activeBarIndex, setActiveBarIndex] = useState();
  const [data, setData] = useState({});
  const [filesData, setFiles] = useState([]);

  const [tags, setTags] = useState({});

  // todo: add fetch data from api
  useEffect(() => {
    setData({ ...FOLDER_RESPONSE_MOCK });
    setFiles([...FILES_MOCK]);

    const formattedTags = Object.entries(FOLDER_RESPONSE_MOCK.tags).reduce(
      (acc, [key, tagsItems]) => ({
        ...acc,
        [key]: formatTags({ tags: tagsItems, category: key, isActive: true }),
      }),
      {},
    );

    setTags({ ...formattedTags });
  }, []);

  const handleTagClick = (tag) => {
    setTags((prevState) => {
      return {
        ...prevState,
        [tag.category]: [...prevState[tag.category]].map((t) => ({
          ...t,
          isActive: t.key === tag.key ? !t.isActive : t.isActive,
        })),
      };
    });
  };

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={data.title} />

        <BreadCrumbs
          currentPage={data.title}
          links={[
            { name: t("dataTitle"), link: "/data" },
            {
              name: categoryTitle,
              link: `/data/${CATEGORIES.HYDROMETEOROLOGY}`,
            },
          ]}
        />

        <div className="overflow-x-scroll">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <Scale
                data={DATA_MOCK_DATA_PRESENCE}
                name={t("measurementCount")}
                activeIndex={activeBarIndex}
                setActiveIndex={setActiveBarIndex}
              />

              <Tags tags={tags} onClick={handleTagClick} />

              <section
                className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
              >
                <Table
                  onRowClick={() => navigator}
                  data={filesData}
                  columns={GEO_FOLDER_COLUMNS}
                  ExpandedRowComponent={({ row }) => (
                    <SingleHydroMeteorologyExpanded row={row} />
                  )}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

SingleHydroMeteorologyPage.propTypes = {
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
};

export default SingleHydroMeteorologyPage;
