import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { LANGUAGE } from "i18n";
import LinkText from "components/link/link-text";
import Layout from "layout/layout";

const HomePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <>
        <div
          className="absolute w-[100%] h-[100%] -z-10
          bg-cover bg-center bg-home-light dark:bg-home-dark"
        />
        <div
          className="flex-grow flex flex-wrap
          bg-gradient-to-b from-[#00263b] dark:from-gray-1100
          from-50% md:bg-gradient-to-l xl:from-10% dark:md:from-10%
          font-normal text-gray-100 dark:text-gray-400 z-20"
        >
          <div className="w-0 md:w-1/4 xl:w-1/2"></div>

          <div className="w-full md:w-3/4 xl:w-1/2 p-8 sm:p-4 lg:p-20 md:p-10">
            <h1 className="text-xl lg:text-4xl mb-4">{t("homeWelcome")}</h1>
            <p className="lg:text-xl mb-4">{t("homeIntro")}</p>
            <p className="text-sm lg:text-l mb-2">
              <Trans
                i18nKey="homeText"
                components={{
                  nasc_link: (
                    <LinkText
                      href={
                        i18n.language === LANGUAGE.UA
                          ? "http://uac.gov.ua/"
                          : "http://uac.gov.ua/en/"
                      }
                      target="_blank"
                      className="underline hover:no-underline"
                    />
                  ),
                  structure_link: (
                    <LinkText
                      href="/research-structure"
                      className="underline hover:no-underline"
                    />
                  ),
                }}
              />
            </p>

            <div className="mt-8 flex gap-4">
              <LinkText
                href="/data"
                className="
              btn h-fit min-h-fit transition-all duration-300 py-4

              border-4 outline-none
              border-color-brands bg-color-brands text-gray-100
              dark:border-color-brands dark:bg-color-brands dark:text-gray-100
              hover:border-accent-neutral hover:bg-neutral hover:text-color-brands"
              >
                {t("homeExplore")}
              </LinkText>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default HomePage;
