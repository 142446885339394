import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import { CATEGORIES } from "api/data/constants";
import { USER_ROLES } from "components/groups/user-groups-settings";

import { CATEGORIES_CONFIG } from "./categories-settings";
import SingleHydroMeteorologyPage from "./hydrometeorology/single-hydrometeorology-page";
import SingleGeologyGeophysicsPage from "./geology-geophysics/single-geology-geophysics-page";
import DataCategories from "./data-categories";
import GeologyList from "./geology-geophysics/geology-list";
import HydroMeteorologyList from "./hydrometeorology/hydrometeorology-list";

const DataPage = ({ role = USER_ROLES.USER }) => {
  const navigate = useNavigate();
  let { id, category } = useParams();

  const isValidCategory =
    category &&
    !!CATEGORIES_CONFIG[category] &&
    !CATEGORIES_CONFIG[category]?.disabled;

  useEffect(() => {
    // todo: add 404 page for invalid id and category
    // if ((category && !isValidCategory) || (id && !isValidId)) {
    if ((category || id) && !isValidCategory) {
      navigate("/data");
    }
  }, [category, isValidCategory, id, navigate]);

  // Categories
  if (!category || !isValidCategory) {
    return <DataCategories />;
  }

  // Single category page
  if (!id) {
    switch (category) {
      case CATEGORIES.GEOLOGY_AND_GEOPHYSICS:
        return <GeologyList />;

      case CATEGORIES.HYDROMETEOROLOGY:
      default:
        return <HydroMeteorologyList />;
    }
  }

  switch (category) {
    case CATEGORIES.HYDROMETEOROLOGY:
      return <SingleHydroMeteorologyPage id={id} />;
    case CATEGORIES.GEOLOGY_AND_GEOPHYSICS:
      return <SingleGeologyGeophysicsPage id={id} />;
    default:
    // return <HydroMeteorologyList role={role} category={category} />;
  }
};
DataPage.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
};

export default DataPage;
