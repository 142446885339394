import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LANGUAGE, LOCAL_STORAGE_LANGUAGE_KEY } from "../i18n";

const Language = ({ isCollapsed }) => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage =
      i18n.language === LANGUAGE.UA ? LANGUAGE.EN : LANGUAGE.UA;
    i18n.changeLanguage(newLanguage);
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, newLanguage);
  };

  const isEN = i18n.language === LANGUAGE.EN;

  return (
    <div className="flex justify-center items-center rounded-[4px] bg-neutral dark:bg-dark-neutral-border p-2">
      <button
        className="flex gap-1 text-lg leading-6 font-extralight"
        onClick={toggleLanguage}
      >
        {/* Відображаємо активну мову */}
        {isCollapsed ? (
          <span className="text-blue-500">{i18n.language.toUpperCase()}</span>
        ) : (
          <>
            <span
              className={`transition-opacity ${
                !isEN ? "opacity-100" : "opacity-50"
              }`}
            >
              EN
            </span>
            <span
              className={`transition-opacity ${
                isEN ? "opacity-100" : "opacity-50"
              }`}
            >
              UA
            </span>
          </>
        )}
      </button>
    </div>
  );
};
Language.propTypes = {
  isCollapsed: PropTypes.bool,
};
export default Language;
