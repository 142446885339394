import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from "react-router-dom";

import LayoutWithSidebar from "layout/layout-with-sidebar";
import BreadCrumbs from "components/breadcrumbs";
import { Tags } from "components/tags";
import TableWithPagination from "components/table/table-with-pagination";
import TagH1 from "components/typography/h1";
import { LoadingContainer } from "components/loading/loading";

import { CATEGORIES } from "api/data/constants";
import { useFetchMetaCategory } from "api/data/api-meta-hooks";
import { formatMetaResponse } from "api/data/utils";

import { GEO_FOLDER_COLUMNS } from "../data-columns";
import { CATEGORIES_CONFIG } from "../categories-settings";

import { useFetchDataCategoryFolders } from "api/data/api-data-hooks";
import { GEOLOGY_FOLDER_PER_PAGE, MIN_GEOLOGY_DATE } from "./constants";

const GeologyList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // STATES
  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [tags, setTags] = useState({});
  const [filesData, setFilesData] = useState({
    data: [],
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(0);

  // META DATA FETCH
  const {
    isLoading: isTagsLoading,
    data: metaData,
    error: tagsError,
  } = useFetchMetaCategory({ category: CATEGORIES.GEOLOGY_AND_GEOPHYSICS });
  useEffect(() => {
    if (!isTagsLoading && !tagsError) {
      const { startDate, endDate, meta } = formatMetaResponse({
        data: metaData,
      });
      setRange({ startDate, endDate });
      setTags(meta);
    }
  }, [isTagsLoading, tagsError, metaData]);

  // DATA FETCH
  const {
    isLoading: isDataLoading,
    error: dataError,
    data,
  } = useFetchDataCategoryFolders({
    category: CATEGORIES.GEOLOGY_AND_GEOPHYSICS,
    limit: GEOLOGY_FOLDER_PER_PAGE,
    offset: GEOLOGY_FOLDER_PER_PAGE * currentPage,
  });
  useEffect(() => {
    if (!isDataLoading && !dataError) {
      setFilesData({
        data: data.data,
        total: data.total,
      });
      setCurrentPage(data.offset / GEOLOGY_FOLDER_PER_PAGE);
    }
  }, [isDataLoading, dataError, data]);

  // HANDLERS
  // todo: add request to API on tags selection , pagination chang, add useMutate?
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleTagClick = (tag) => {
    setTags((prevState) => {
      return {
        ...prevState,
        [tag.category]: [...prevState[tag.category]].map((t) => ({
          ...t,
          isActive: t.key === tag.key ? !t.isActive : t.isActive,
        })),
      };
    });
  };

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1
          text={t(
            CATEGORIES_CONFIG[CATEGORIES.GEOLOGY_AND_GEOPHYSICS].labelKey,
          )}
        />

        <BreadCrumbs
          currentPage={t(
            CATEGORIES_CONFIG[CATEGORIES.GEOLOGY_AND_GEOPHYSICS].labelKey,
          )}
          links={[{ name: t("dataTitle"), link: "/data" }]}
        />

        <div className="overflow-x-scroll">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <LoadingContainer isLoading={isTagsLoading} error={tagsError}>
                <>
                  <Tags
                    tags={tags}
                    onClick={handleTagClick}
                    groupByCategory={true}
                    className="border bg-neutral-bg dark:bg-dark-neutral-bg
                      border-neutral  dark:border-dark-neutral-border rounded-2xl
                      p-6 flex flex-col"
                  />

                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="min-w-[300px] ml-auto">
                      <Datepicker
                        value={range}
                        onChange={(newValue) => setRange(newValue)}
                        minDate={MIN_GEOLOGY_DATE}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </>
              </LoadingContainer>

              <LoadingContainer isLoading={isDataLoading} error={dataError}>
                <section
                  className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
                >
                  <TableWithPagination
                    onRowClick={({ id }) =>
                      navigate(`/data/geology-and-geophysics/${id}`)
                    }
                    data={filesData.data || []}
                    columns={GEO_FOLDER_COLUMNS}
                    total={filesData.total || 0}
                    setCurrentPage={handlePageChange}
                    currentPage={currentPage}
                    itemsPerPage={GEOLOGY_FOLDER_PER_PAGE}
                  />
                </section>
              </LoadingContainer>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default GeologyList;
