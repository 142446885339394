import { useQuery } from "react-query";
import { DATA_KEYS } from "./api-keys";
import { fetchData } from "./api-service";

// GET paginated folders for a category [/data/:category]
// {
//   data: [
//     {
//       id: string,
//       name: string,
//       description: string,
//       descriptionEn: string,
//       count: number,
//       size: number, //? maybe string
//     },
//   ],
//   total: number,
//   offset: number,
//   limit: number,
// };
export function useFetchDataCategoryFolders({ category, limit, offset }) {
  // Fetch data
  const { data, error, isLoading } = useQuery(DATA_KEYS.DATA, () =>
    fetchData({
      category,
      limit,
      offset,
    }),
  );

  return { data, error, isLoading };
}

// GET paginated files for a category folder [/data/:category/:folderId]
// {
//   data: [
//     {
//       id: string,
//       name: string,
//       type: string,
//       date: string, // ISO date
//       size: number, // todo: check, maybe string
//       downloadUrl: string,
//     },
//   ],
//   total: number,
//   offset: number,
//   limit: number,
// }

export function useFetchDataCategoryFolderFiles({
  category,
  folderId,
  limit,
  offset,
}) {
  // Fetch data
  const { data, error, isLoading } = useQuery(
    DATA_KEYS.getFolderKeys({ category, folderId, limit, offset }),
    () =>
      fetchData({
        category,
        folderId,
        limit,
        offset,
      }),
  );

  return { data, error, isLoading };
}

// GET single file data [/data/:category/:folderId/:fileId]
// {
//   meta: {
//     string: [string, string...],
//     ...
//   },
//   data: {
//     type: 'series'| ...
//     data: {
//       dates: [ISODate, ISODate, ...],
//       x: [number, number, ...],
//       y: [number, number, ...],
//     }
//   },
// }
export function useFetchSingleDataCategoryFolderFile({
  category,
  folderId,
  fileId,
}) {
  // Fetch data
  const { data, error, isLoading } = useQuery(
    DATA_KEYS.getFileKeys({ category, folderId, fileId }),
    () =>
      fetchData({
        category,
        folderId,
        fileId,
      }),
  );

  return { data, error, isLoading };
}
