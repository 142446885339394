import axios from "axios";

const API_URL = "/api/v1";
const DATA_URL = `${API_URL}/data`;
const META_URL = `${API_URL}/meta`;

export const fetchData = async ({
  category,
  folderId,
  fileId,
  limit = 10,
  offset = 0,
}) => {
  let route = `${DATA_URL}/${category}`;
  let params = {};

  if (folderId) {
    route += `/${folderId}`;
  }

  if (folderId && fileId) {
    route += `/${fileId}`;
  } else {
    params = { limit, offset };
  }
  const response = await axios.get(route, { params });
  return response.data;
};

export const fetchMeta = async ({ category, folderId }) => {
  let route = `${META_URL}/${category}`;

  if (folderId) {
    route += `/${folderId}`;
  }

  const response = await axios.get(route);
  return response.data;
};
