import { FaCompass, FaTemperatureLow } from "react-icons/fa";
import { TbUniverse } from "react-icons/tb";
import { GiBigWave, GiDna1 } from "react-icons/gi";
import { MdOutlineScience } from "react-icons/md";
import { CATEGORIES } from "api/data/constants";

import biology from "img/categories/biology.png";
import biologyDark from "img/categories/biology_modified.png";
import geocosm from "img/categories/geocosm.png";
import geocosmDark from "img/categories/geocosm_modified.png";
import geology from "img/categories/geology.png";
import geologyDark from "img/categories/geology_modified.png";
import geometeoroly from "img/categories/geometeoroly.png";
import geometeorolyDark from "img/categories/geometeoroly_modified.png";
import medicine_physiology from "img/categories/medicine_physiology.png";
import medicine_physiologyDark from "img/categories/medicine_physiology_modified.png";
import oceanography from "img/categories/oceanography.png";
import oceanographyDark from "img/categories/oceanography_modified.png";

export const CATEGORIES_CONFIG = {
  [CATEGORIES.GEOLOGY_AND_GEOPHYSICS]: {
    value: CATEGORIES.GEOLOGY_AND_GEOPHYSICS,
    labelKey: "geo",
    img: geology,
    darkImg: geologyDark,
    icon: FaCompass,
    link: "/data/geology-and-geophysics",
  },
  [CATEGORIES.GEOCOSMOS]: {
    value: CATEGORIES.GEOCOSMOS,
    labelKey: "geocosmos",
    img: geocosm,
    darkImg: geocosmDark,
    link: "/data/geo-cosmos",
    disabled: true,
    icon: TbUniverse,
  },
  [CATEGORIES.HYDROMETEOROLOGY]: {
    value: CATEGORIES.HYDROMETEOROLOGY,
    labelKey: "hydrometeorology",
    img: geometeoroly,
    darkImg: geometeorolyDark,
    link: "/data/hydrometeorology",
    icon: FaTemperatureLow,
  },
  [CATEGORIES.OCEANOGRAPHY]: {
    value: CATEGORIES.OCEANOGRAPHY,
    labelKey: "oceanography",
    img: oceanography,
    darkImg: oceanographyDark,
    link: "/data/oceanography",
    disabled: true,
    icon: GiBigWave,
  },
  [CATEGORIES.BIOLOGY]: {
    value: CATEGORIES.BIOLOGY,
    labelKey: "biology",
    img: biology,
    darkImg: biologyDark,
    link: "/data/biology",
    disabled: true,
    icon: MdOutlineScience,
  },
  [CATEGORIES.MEDICINE_AND_PHYSIOLOGY]: {
    value: CATEGORIES.MEDICINE_AND_PHYSIOLOGY,
    labelKey: "medicine_physiology",
    img: medicine_physiology,
    darkImg: medicine_physiologyDark,
    link: "/data/medicine-and-physiology",
    disabled: true,
    icon: GiDna1,
  },
};

export const SUB_CATEGORIES = {
  OZONE: "ozone",
};

export const SUB_CATEGORIES_CONFIG = {
  [SUB_CATEGORIES.OZONE]: {
    value: SUB_CATEGORIES.OZONE,
    labelKey: "ozone",
    parent: CATEGORIES.HYDROMETEOROLOGY,
  },
};
