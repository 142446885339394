import React, { useState } from "react";
import PropTypes from "prop-types";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { TABLE_ROW_ACTION_SHAPE } from "./shapes";
import { useTranslation } from "react-i18next";

export const ACTION_KEY = "actions";
export const ACTION_PLACEMENTS = {
  TOP: "top",
  BOTTOM: "bottom",
};

const CellAction = ({
  actions = [],
  row = {},
  placement = ACTION_PLACEMENTS.BOTTOM,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const toggleActions = (e) => {
    e.stopPropagation();
    setShow(!show);
  };

  return (
    <div
      className={`dropdown dropdown- ${placement === ACTION_PLACEMENTS.BOTTOM ? "dropdown-end" : "dropdown-left"}`}
      onClick={toggleActions}
    >
      <button
        className="cursor-pointer dropdown-label flex items-center justify-between p-4"
        tabIndex="0"
        onClick={toggleActions}
      >
        <HiOutlineDotsHorizontal className="z-0" />
      </button>
      {show && (
        <ul className="dropdown-content relative z-10" tabIndex="0">
          <div
            className="relative menu
            dropdown-shadow
            rounded-box bg-neutral-bg border border-neutral-border dark:border-dark-neutral-border dark:bg-dark-neutral-bg
            min-w-[200px] mt-1
            dark:text-gray-dark-500"
          >
            <div className="border-solid border-b-8 border-x-transparent border-x-8 border-t-0 absolute w-[14px] top-[-7px] border-b-transparent right-[18px]"></div>
            {actions.map(
              ({ onClick = () => {}, name, labelKey, className }, idx) => (
                <li key={`${name}-${idx}`}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(row);
                    }}
                    className={`p-2 text-normal hover:bg-transparent hover:text-color-brands active:bg-transparent ${className}`}
                  >
                    {t(labelKey)}
                  </button>
                </li>
              ),
            )}
          </div>
        </ul>
      )}
    </div>
  );
};

CellAction.propTypes = {
  actions: TABLE_ROW_ACTION_SHAPE,
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
  placement: PropTypes.oneOf(Object.values(ACTION_PLACEMENTS)),
};

export default CellAction;
