import React from "react";
import PropTypes from "prop-types";
import { BiCollapseVertical, BiExpandVertical } from "react-icons/bi";

export default function ExpandButton({ trIdx, expandedRowIdx }) {
  return (
    <div className="block p-2">
      {expandedRowIdx === trIdx ? <BiCollapseVertical /> : <BiExpandVertical />}
    </div>
  );
}
ExpandButton.propTypes = {
  trIdx: PropTypes.number.isRequired,
  expandedRowIdx: PropTypes.number,
};
