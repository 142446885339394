import { USER_ROLES } from "../groups/user-groups-settings";

export const COLUMN_TYPES = {
  DATE: "date",
  ROLE: "role",
  ACTIONS: "actions",
  BOOLEAN: "boolean",
  STAGE: "stage",
  GROUP_CATEGORY: "category",
  GROUP_SUB_CATEGORY: "subCategory",
  DOWNLOAD: "download",
};

export const ROLE_TO_KEY = {
  [USER_ROLES.ADMIN]: "roleAdmin",
  [USER_ROLES.MEMBER]: "roleMember",
  [USER_ROLES.USER]: "roleUser",
  [USER_ROLES.CHIEF]: "roleChief",
};

export const STAGE_TO_KEY = {
  raw: "stageRaw",
  processed: "stageProcessed",
  filtered: "stageFiltered",
};
