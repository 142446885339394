import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";

import LayoutWithSidebar from "layout/layout-with-sidebar";
import BreadCrumbs from "components/breadcrumbs";
import { Tags } from "components/tags";
import Table from "components/table/table";
import TagH1 from "components/typography/h1";
import { LoadingContainer } from "components/loading/loading";

import { CATEGORIES } from "api/data/constants";
import { useFetchMetaCategoryFolder } from "api/data/api-meta-hooks";
import { formatMetaResponse } from "api/data/utils";

import { CATEGORIES_CONFIG } from "../categories-settings";
import { SINGLE_FILES_COLUMNS_GEO } from "../data-columns";

import SingleGeologyGeophysicsExpanded from "./single-geology-geophysics-expanded";
import { FILES_MOCK } from "./files-mock";
import { GEOLOGY_AND_GEOPHYSICS_SINGLE_RESPONSE_MOCK } from "./magnet-files-mock";

const SingleGeologyGeophysicsPage = ({ id }) => {
  const [data, setData] = useState({});
  const [tags, setTags] = useState({});
  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const { t } = useTranslation();
  const categoryTitle = t(
    CATEGORIES_CONFIG[CATEGORIES.GEOLOGY_AND_GEOPHYSICS].labelKey,
  );

  // META DATA FETCH
  const {
    isLoading: isTagsLoading,
    data: metaData,
    error: tagsError,
  } = useFetchMetaCategoryFolder({
    category: CATEGORIES.GEOLOGY_AND_GEOPHYSICS,
    folderId: id,
  });
  useEffect(() => {
    if (!isTagsLoading && !tagsError) {
      const { startDate, endDate, meta } = formatMetaResponse({
        data: metaData,
        isActive: true,
      });
      setRange({ startDate, endDate });
      setTags(meta);
    }
  }, [isTagsLoading, tagsError, metaData]);

  useEffect(() => {
    setData({ ...GEOLOGY_AND_GEOPHYSICS_SINGLE_RESPONSE_MOCK });
  }, []);
  const filesData = [...FILES_MOCK];

  const handleTagClick = (tag) => {
    setTags((prevState) => {
      return {
        ...prevState,
        [tag.category]: [...prevState[tag.category]].map((t) => ({
          ...t,
          isActive: t.key === tag.key ? !t.isActive : t.isActive,
        })),
      };
    });
  };

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={data.title} />

        <BreadCrumbs
          currentPage={data.title}
          links={[
            { name: t("dataTitle"), link: "/data" },
            {
              name: categoryTitle,
              link: `/data/${CATEGORIES.GEOLOGY_AND_GEOPHYSICS}`,
            },
          ]}
        />

        <div className="overflow-x-scroll">
          <div className="min-w-[400px]">
            <div className="flex flex-col gap-8">
              <LoadingContainer isLoading={isTagsLoading} error={tagsError}>
                <>
                  <Tags
                    tags={tags}
                    onClick={handleTagClick}
                    groupByCategory={true}
                    className="border bg-neutral-bg dark:bg-dark-neutral-bg
                      border-neutral  dark:border-dark-neutral-border rounded-2xl
                      p-6 flex flex-col"
                  />

                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="min-w-[300px] ml-auto">
                      <Datepicker
                        value={range}
                        onChange={(newValue) => setRange(newValue)}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </>
              </LoadingContainer>

              <section
                className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
                  rounded-2xl p-8 scrollbar-hide lg:overflow-x-hidden"
              >
                <Table
                  data={filesData}
                  columns={SINGLE_FILES_COLUMNS_GEO}
                  ExpandedRowComponent={({ row }) => (
                    <SingleGeologyGeophysicsExpanded row={row} />
                  )}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

SingleGeologyGeophysicsPage.propTypes = {
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
};

export default SingleGeologyGeophysicsPage;
