import React from "react";
import { TbFilterSearch } from "react-icons/tb";
import { LuUser } from "react-icons/lu";
import { GiPapers } from "react-icons/gi";
import { SiInstructure } from "react-icons/si";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import logo from "img/logo/logo.png";
import { IoIosArrowForward } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CATEGORIES_CONFIG } from "pages/data/categories-settings";

import Settings from "../settings/settings";
import LinkText from "../link/link-text";

const items = [
  {
    id: 1,
    labelKey: "structureTitle",
    link: "/research-structure",
    icon: SiInstructure,
  },
  ...Object.values(CATEGORIES_CONFIG).map((category, idx) => ({
    id: idx + 2,
    ...category,
  })),
  {
    id: 11,
    labelKey: "accountTitle",
    link: "/account",
    icon: LuUser,
    disabled: true,
  },
  {
    id: 12,
    labelKey: "relatedPapersTitle",
    link: "/related-papers",
    icon: GiPapers,
  },
];

const NavbarItem = ({ item = {}, isSideBarHidden }) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="flex justify-center h-[16px] w-[20px]">
        <item.icon />
      </span>
      <div
        className={`flex text-normal font-semibold ${isSideBarHidden ? " md:hidden" : ""}`}
      >
        {t(item.labelKey)}
      </div>
    </>
  );
};

const Navbar = ({ isSideBarHidden, toggleSidebar, content = null }) => {
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();

  const hasContent = content !== null;

  return (
    <aside
      className={`
        relative flex flex-col justify-between row-span-2 p-4 md:pb-4 md:gap-4 h-full max-h-100vh md:overflow-y-auto
        border-r border-neutral bg-white dark:bg-dark-neutral-bg dark:border-dark-neutral-border
        text-normal text-gray-500 dark:text-gray-dark-500
        ${isSideBarHidden ? "" : " pb-8 gap-4 "}
    `}
    >
      <button
        className={`absolute md:fixed 
            p-2 left-[50%] ml-[-16px] md:ml-0 md:top-5 bottom-[-15px] md:bottom-auto ${isSideBarHidden ? "md:left-[46px]" : "md:left-[224px]"}
            border-neutral border bg-white rounded-full dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            md:translate-x-1/2 duration-300 hover:opacity-75 
            cursor-pointer z-50 
            rotate-90 md:rotate-0`}
        onClick={toggleSidebar}
      >
        {!isSideBarHidden ? (
          <MdOutlineArrowBackIosNew />
        ) : (
          <IoIosArrowForward />
        )}
      </button>
      <div className="flex items-center gap-3 z-40">
        <LinkText className="flex items-center text-center gap-3 pl-2" href="/">
          <img
            className="md:h-[40px] lg:h-[40px] h-[40px] xs:h-[30px]"
            src={logo}
            alt="Vernadsky Research Base"
          />
          <span
            className={`font-light text-2l leading-5 text-gray-900 text-left
                bg-neutral-bg dark:text-gray-300 dark:bg-dark-neutral-bg ${isSideBarHidden ? "md:hidden" : ""}`}
          >
            {t("vernadskyResearchBaseShort")}
          </span>
        </LinkText>
      </div>

      <menu
        className={`
          flex flex-col md:gap-3 overflow-hidden flex-shrink-0
          border-t border-b dark:border-dark-neutral-border md:border-none
          ${isSideBarHidden ? "border-none h-0 md:h-auto" : "py-4 md:py-0"}
        `}
      >
        {items.map((item) => (
          <li
            key={item.id}
            className={`rounded-xl relative ${pathname.includes(item.link) ? " bg-[var(--color-brands)] text-gray-100" : ""}`}
          >
            {!!item.disabled && (
              <div className="flex items-center gap-4 cursor-pointer p-3 opacity-50 focus:outline-none">
                <NavbarItem item={item} isSideBarHidden={isSideBarHidden} />
              </div>
            )}

            {!item.disabled && (
              <LinkText
                href={item.link}
                className={`flex items-center gap-4 cursor-pointer p-3
                focus:outline-none ${pathname.includes(item.link) ? "text-gray-100" : ""}`}
              >
                <NavbarItem item={item} isSideBarHidden={isSideBarHidden} />
              </LinkText>
            )}
          </li>
        ))}
      </menu>

      {hasContent && (
        <div className="mb-auto">
          <div className="border-t border-neutral dark:border-dark-neutral-border pt-8">
            {isSideBarHidden ? (
              <span className="flex justify-center h-[16px] w-[40px]">
                <TbFilterSearch />
              </span>
            ) : (
              content
            )}
          </div>
        </div>
      )}

      <div
        className={`mx-auto mt-auto flex-shrink-0 overflow-hidden ${isSideBarHidden ? "h-0 md:h-auto" : ""}`}
      >
        <Settings isCollapsed={isSideBarHidden} />
      </div>
    </aside>
  );
};

export default Navbar;
