import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";

import { CATEGORIES } from "api/data/constants";
import { getRandomData } from "./hydro-files-mock";

import LayoutWithSidebar from "layout/layout-with-sidebar";
import BreadCrumbs from "components/breadcrumbs";
import TableWithPagination from "components/table/table-with-pagination";
import { Tags } from "components/tags";
import { USER_ROLES } from "components/groups/user-groups-settings";
import TagH1 from "components/typography/h1";

import { ENTITY_COLUMNS, MAKE_ACTIONS_LABELS } from "../data-columns";
import { CATEGORIES_CONFIG } from "../categories-settings";
import { HYDRO_METEOROLOGY_TAGS } from "./constants";

const FILES_PER_PAGE = 10;

const HydroMeteorologyList = ({ role }) => {
  const category = CATEGORIES.HYDROMETEOROLOGY;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [tags, setTags] = useState({ ...HYDRO_METEOROLOGY_TAGS });

  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const handleTagClick = (tag) => {
    setTags((prevState) => ({
      ...prevState,
      [tag.category]: [...prevState[tag.category]].map((t) => ({
        ...t,
        isActive: t.key === tag.key ? !t.isActive : t.isActive,
      })),
    }));
  };

  const actions = Object.values(MAKE_ACTIONS_LABELS).map((item) => ({
    ...item,
    // todo: replace with real url
    onClick: () => {
      navigate("/data/hydrometeorology/1sakjshkywiqqe");
    },
  }));

  // todo: replace with request to API
  // todo: fix for data Геолого-геофізичні дослідження
  const { data, totalCount } = useMemo(() => {
    return getRandomData({ currentPage, filesPerPage: FILES_PER_PAGE });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <LayoutWithSidebar>
      <div>
        <TagH1 text={t(CATEGORIES_CONFIG[category].labelKey)} />

        <BreadCrumbs
          currentPage={t(CATEGORIES_CONFIG[category].labelKey)}
          links={[{ name: t("dataTitle"), link: "/data" }]}
        />

        <div className="flex flex-col gap-8">
          <Tags tags={tags} onClick={handleTagClick} groupBy="category" />

          <div className="flex flex-col md:flex-row justify-between">
            <div className="min-w-[300px] ml-auto">
              <Datepicker
                value={range}
                onChange={(newValue) => setRange(newValue)}
                maxDate={new Date()}
              />
            </div>
          </div>
          <section
            className="border bg-neutral-bg border-neutral dark:bg-dark-neutral-bg dark:border-dark-neutral-border
            rounded-2xl p-8 pb-30px scrollbar-hide lg:overflow-x-hidden"
          >
            <TableWithPagination
              columns={ENTITY_COLUMNS}
              rowActions={actions}
              data={data}
              onRowClick={({ id }) => navigate(`/data/${category}/${id}`)}
              total={totalCount}
              setCurrentPage={handlePageChange}
              currentPage={currentPage}
              itemsPerPage={FILES_PER_PAGE}
            />
          </section>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};
HydroMeteorologyList.propTypes = {
  role: PropTypes.oneOf(Object.values(USER_ROLES)),
  category: PropTypes.oneOf(Object.values(CATEGORIES)),
};

export default HydroMeteorologyList;
