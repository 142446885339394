import React from "react";
import { LANGUAGE } from "i18n";
import { getIdFromTitle } from "./menu-item";

const RelatedPage = ({
  title,
  titleEn,
  pages,
  link,
  descr,
  descrEn,
  level = 0,
  idx,
  language,
}) => {
  const isEn = language === LANGUAGE.EN;

  const descrText = (isEn && descrEn) || descr;
  const hasDescr = Boolean(descrText);
  const isDescrArr = hasDescr && Array.isArray(descrText);

  const isFirstLevel = level < 2;
  const isSecondLevel = level === 2;
  const isThirdLevel = level > 2;

  const titleText = (isEn && titleEn) || title;
  const titleTextId = getIdFromTitle(titleEn || title);

  return (
    <div
      id={titleTextId}
      className={
        (isFirstLevel && "mb-8") || (isSecondLevel && "mb-6") || "mb-4"
      }
    >
      {!!titleText && (
        <>
          {isFirstLevel && <h3 className="text-2xl mb-4 pt-4">{titleText}</h3>}

          {isSecondLevel && (
            <h4 className="text-xl py-4 mt-8 mb-4 border-t border-b dark:border-dark-neutral-border">
              {titleText}
            </h4>
          )}

          {isThirdLevel && (
            <p className="mb-1">
              {idx + 1}. {titleText}
            </p>
          )}
        </>
      )}

      {hasDescr && (
        <>
          {isDescrArr ? (
            descrText.map((d, i) => (
              <p key={i} className="text-sm italic">
                {d}
              </p>
            ))
          ) : (
            <p className="text-sm italic">{descrText}</p>
          )}
        </>
      )}

      {!!link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:no-underline"
        >
          {link}
        </a>
      )}

      {!!pages &&
        pages.map((page, idx) => (
          <RelatedPage
            level={level + 1}
            key={idx}
            {...page}
            idx={idx}
            language={language}
          />
        ))}
    </div>
  );
};

export default RelatedPage;
