import { formatTags } from "components/tags";

export const HYDRO_METEOROLOGY_TAGS_CATEGORIES = {
  TEMPERATURE: "temperature",
  IS_PUBLIC: "isPublic",
};

export const HYDRO_METEOROLOGY_TAGS = {
  [HYDRO_METEOROLOGY_TAGS_CATEGORIES.TEMPERATURE]: formatTags({
    tags: [0, 10, -10],
    category: HYDRO_METEOROLOGY_TAGS_CATEGORIES.TEMPERATURE,
  }),
  [HYDRO_METEOROLOGY_TAGS_CATEGORIES.IS_PUBLIC]: formatTags({
    tags: [true, false],
    category: HYDRO_METEOROLOGY_TAGS_CATEGORIES.IS_PUBLIC,
  }),
};
