import React from "react";
import MenuItem from "./menu-item";

const MenuPapers = ({ pages = [], language }) => {
  const getHasSubPages = ({ pages: pg }) =>
    pg?.some(({ pages: subPg }) => subPg?.length);

  return (
    <ul>
      {pages.map((page, idx) => (
        <li key={idx} className="mb-2">
          <MenuItem
            title={page.title}
            titleEn={page.titleEn}
            language={language}
          />

          {getHasSubPages(page) && (
            <div className="ml-4 text-sm md:text-l">
              <MenuPapers pages={page.pages} language={language} />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MenuPapers;
