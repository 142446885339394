import axios from "axios";
import MockAdapter from "axios-mock-adapter";

import { CATEGORIES } from "../constants";

import GeoMeta from "./geo-meta-mock.json";
import GeoFolderMeta from "./geo-meta-single-folder-mock.json";
import GeoCategoryFoldersData from "./geo-category-folders-mock.json";

const mock = new MockAdapter(axios, { delayResponse: 500 });

const API_URL = "/api/v1";
const DATA_URL = `${API_URL}/data`;
const META_URL = `${API_URL}/meta`;

const startDate = new Date("2017-10-05T14:48:00.000Z").toISOString();
const endDate = new Date("2024-10-05T14:48:00.000Z").toISOString();

mock.onGet(new RegExp(`${DATA_URL}/.*`)).reply((config) => {
  // const { fileId, limit = 10, offset = 0 } = config.params;
  const { fileId } = config.params;

  const [category, folderId] = config.url
    .replace("/api/v1/data/", "")
    .split("/");

  if (category === CATEGORIES.GEOLOGY_AND_GEOPHYSICS) {
    return [
      200,
      {
        ...(folderId ? GeoFolderMeta : GeoCategoryFoldersData),
        range: [startDate, endDate],
      },
    ];
  }

  let data = {
    /* your mock data here */
  };

  // Customize the response based on the request parameters
  if (folderId) {
    data = {
      /* mock data for folderId */
    };
  }

  if (folderId && fileId) {
    data = {
      /* mock data for fileId */
    };
  } else {
    data = {
      /* mock data for pagination */
    };
  }

  return [200, data];
});

// Mock fetchMeta endpoint

mock.onGet(new RegExp(`${META_URL}/.*`)).reply((config) => {
  const [category, folderId] = config.url
    .replace("/api/v1/meta/", "")
    .split("/");

  if (category === CATEGORIES.GEOLOGY_AND_GEOPHYSICS) {
    return [200, { ...(folderId ? GeoFolderMeta : GeoMeta) }];
  }

  return [200, { ...(folderId ? GeoFolderMeta : GeoMeta) }];
});

export default mock;
