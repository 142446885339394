import React from "react";
import { useTranslation } from "react-i18next";
import { FiArrowUpCircle } from "react-icons/fi";
import CONTENT_PAPERS from "./content-papers.json";
import RelatedPage from "./related-page";
import MenuPapers from "./menu-pages";
import Layout from "layout/layout";

const RelatedPages = () => {
  const { i18n, t } = useTranslation();
  const handleUpClick = () => {
    document.querySelector(`#relatedPapersTitle`).scrollIntoView();
  };
  return (
    <Layout>
      <div className="flex-grow flex flex-wrap py-4 md:p-10 dark:text-gray-500">
        <div className="w-full md:w-2/4 xl:w-1/4">
          <div className="h-full overflow-auto pt-3">
            <h1
              className="text-2xl md:text-2xl ml-4 mb-4"
              id="relatedPapersTitle"
            >
              {t("relatedPapersTitle")}
            </h1>

            <div className="text-xl ml-4">
              <MenuPapers
                pages={CONTENT_PAPERS.pages}
                language={i18n.language}
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/4 xl:w-3/4">
          <div className="h-full mx-4 md:mx-0 overflow-x-hidden overflow-y-auto">
            <RelatedPage
              pages={CONTENT_PAPERS.pages}
              title={CONTENT_PAPERS.title}
              language={i18n.language}
            />

            <button
              onClick={handleUpClick}
              className="text-2xl fixed p-4 right-4 bottom-10 md:bottom-[110px]"
            >
              <FiArrowUpCircle />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RelatedPages;
