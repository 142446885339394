import React from "react";
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const LINE_CHART_MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 30,
};
export const LABEL_POSITION = {
  angle: -90,
  position: "left",
  dy: -16,
  dx: -20,
  fontSize: 12,
};

const GeologyChart = ({
  chartData,
  yLabel = {},
  color,
  getTooltipContent = () => {},
  domain = ["dataMin - 40", "dataMax + 40"],
  showLine = true,
}) => {
  return (
    <>
      {showLine && <hr className="h-1 dark:opacity-50" />}
      <ResponsiveContainer width="100%" height={150}>
        <LineChart data={chartData} margin={LINE_CHART_MARGIN}>
          <CartesianGrid
            strokeDasharray="3 3"
            strokeWidth={0.1}
            stroke={"currentColor"}
          />
          <YAxis
            domain={domain}
            tickSize={4}
            label={yLabel}
            tick={{ fontSize: 12 }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke={color}
            name={yLabel.value}
            dot={false}
            borderBottom={4}
          />
          <Tooltip isAnimationActive={false} content={getTooltipContent} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default GeologyChart;
