export const DEFAULT_TAG_CATEGORY = "_";

export const formatTags = ({ tags, category, isActive = false }) => {
  return [
    ...Object.values(tags).map((name) => ({
      category: category,
      key: `${name}`,
      name: name,
      isActive,
    })),
  ];
};
