import { formatTags } from "components/tags";

export const MAGNET_TAGS = {
  VECTOR_INSTRUMENT: "vector_instrument",
  SCALAR_INSTRUMENT: "scalar_instrument",
  DATA_TYPE: "data_type",
  DATA_INTERVAL: "data_interval",
};

export const VECTOR_INSTRUMENTS = {
  PRIMARY: "Primary",
  LEMI_008_002: "LEMI-008 #002",
  LEMI_008_016: "LEMI-008 #016",
  LEMI_025_063: "LEMI-025 #063",
  LEMI_025_080: "LEMI-025 #080",
};
export const SCALAR_INSTRUMENTS = {
  PRIMARY: "Primary",
  POS_1: "POS-1",
  GSM_90: "GSM-90",
};
export const DATA_TYPES = {
  PRELIMINARY: "preliminary",
  DEFINITIVE: "definitive",
  QUASI_DEFINITIVE: "quasi-definitive",
};

export const DATA_INTERVALS = {
  MINUTE: "minute",
  SECOND: "second",
};

export const GEOLOGY_AND_GEOPHYSICS_TAGS = {
  [MAGNET_TAGS.VECTOR_INSTRUMENT]: formatTags({
    category: MAGNET_TAGS.VECTOR_INSTRUMENT,
    tags: Object.values(VECTOR_INSTRUMENTS),
  }),

  [MAGNET_TAGS.SCALAR_INSTRUMENT]: formatTags({
    category: MAGNET_TAGS.SCALAR_INSTRUMENT,
    tags: Object.values(SCALAR_INSTRUMENTS),
  }),

  [MAGNET_TAGS.DATA_TYPE]: formatTags({
    category: MAGNET_TAGS.DATA_TYPE,
    tags: Object.values(DATA_TYPES),
  }),

  [MAGNET_TAGS.DATA_INTERVAL]: formatTags({
    category: MAGNET_TAGS.DATA_INTERVAL,
    tags: Object.values(DATA_INTERVALS),
  }),
};

export const GEOLOGY_FOLDER_PER_PAGE = 10;
export const MIN_GEOLOGY_DATE = new Date("2018-01-01");
