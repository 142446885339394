import React from "react";
import { CATEGORIES_CONFIG } from "./categories-settings";
import Category from "./category";

const Categories = () => {
  const categories = Object.values(CATEGORIES_CONFIG);
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-6">
      {categories.map((item, index) => (
        <Category key={index} {...item} />
      ))}
    </div>
  );
};

export default Categories;
