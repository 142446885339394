import React from "react";
import PropTypes from "prop-types";

import LineChartCustomized from "components/charts/line-chart";

const SingleHydroMeteorologyExpanded = ({
  row: { chartData, meta } = {},
} = {}) => {
  if (!chartData && !meta) return null;

  return (
    <div className="w-full flex border-t dark:border-dark-neutral-border p-4 shadow-inner">
      {meta && (
        <div className="w-1/4 py-4 text-sm flex flex-col gap-2">
          {Object.entries(meta).map(([key, value], idx) => (
            <div key={idx}>
              {key}: {value}
            </div>
          ))}
        </div>
      )}
      {chartData && (
        <div className="w-3/4 py-4">
          <LineChartCustomized
            data={chartData}
            minDiff={-10}
            maxDiff={10}
            name="Total column ozone (DU)"
            xAxisKey="time"
            key="value"
          />
        </div>
      )}
    </div>
  );
};

SingleHydroMeteorologyExpanded.propTypes = {
  row: PropTypes.shape({
    chartData: PropTypes.arrayOf(
      PropTypes.shape({
        time: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }),
};

export default SingleHydroMeteorologyExpanded;
